var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('customerLoanRequest.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('customerLoanRequest.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":function($event){return _vm.$router.push({ name: 'customer-loan-request-create' })}}},[_vm._v(_vm._s(_vm.$t("addNew"))+" ")]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('customerLoanRequest.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ts-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('filter')),expression:"$t('filter')"}],on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_c('i',{staticClass:"fa fa-filter"})])],1)],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"photo",fn:function(ref){
        var row = ref.row;
return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                        src: _vm.photo(row.customer),
                        error:
                            row.customer.gender == 'Male'
                                ? require('@/assets/staff-default-man.png')
                                : require('@/assets/staff-default-women.png'),
                        loading: require('@/assets/Rolling-1s-200px.svg'),
                        preLoad: 1.3,
                        attempt: 1
                    }),expression:"{\n                        src: photo(row.customer),\n                        error:\n                            row.customer.gender == 'Male'\n                                ? require('@/assets/staff-default-man.png')\n                                : require('@/assets/staff-default-women.png'),\n                        loading: require('@/assets/Rolling-1s-200px.svg'),\n                        preLoad: 1.3,\n                        attempt: 1\n                    }"}],staticClass:"tw-rounded tw-h-8"})]}},{key:"branch",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.branch ? row.branch.branch_name_en : "")+" ")]}},{key:"customer_code",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.customer ? row.customer.customer_code : "")+" ")]}},{key:"customer_name",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.customer ? _vm.locale == "kh" ? row.customer.customer_name_kh : row.customer.customer_name_en : "")+" ")]}},{key:"loan_type",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan_type ? _vm.locale == "kh" ? row.loan_type.loan_type_kh : row.loan_type.loan_type_en : "")+" ")]}},{key:"request_amount",fn:function(ref){
                    var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-blue"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode(row.request_amount, row.currency)))])]}},{key:"request_interest_rate",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.request_interest_rate ? row.request_interest_rate + "%" : "")+" ")]}},{key:"approve_status",fn:function(ref){
                    var row = ref.row;
return [(
                        row.approval_user.length == 0 ||
                            row.approve_status.approve_status_id == 2 ||
                            row.approve_status.approve_status_id == 3 ||
                            row.approve_status.approve_status_id == 4
                    )?_c('span',{class:_vm.statusStyle(row.approve_status.approve_status_id)},[_vm._v(" "+_vm._s(_vm.status(row.approve_status.approve_status_id) + (row.approve_status.approve_status_id == 0 || row.approve_status.approve_status_id == 1 ? row.current_app_level ? " Lv:" + row.current_app_level : "" : ""))+" ")]):_c('Poptip',{staticClass:" tw-text-left",attrs:{"trigger":"hover","title":"Approval user","theme":"light"},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.approvalLevel(
                                row.approval_user
                            )),function(level,index){return _c('ul',{key:index},[_c('b',{staticClass:"tw-grid ts-grid-cols-2  tw-text-left"},[_vm._v(_vm._s("Level: " + index))]),_c('div',{staticClass:"tw-grid ts-grid-cols-2 tw-text-left"},_vm._l((level),function(users,index){return _c('li',{key:index,staticClass:"tw-px-2 tw-whitespace-no-wrap"},[_vm._v(" - Name: "+_vm._s(users.employee.employee_name_en)+" ")])}),0)])})},proxy:true}],null,true)},[_c('span',{class:_vm.statusStyle(
                                row.approve_status.approve_status_id
                            )},[_vm._v(_vm._s(_vm.status(row.approve_status.approve_status_id) + (row.approve_status.approve_status_id == 0 || row.approve_status.approve_status_id == 1 ? row.current_app_level ? " Lv:" + row.current_app_level : "" : "")))])])]}},{key:"ref_loan_number",fn:function(ref){
                            var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan_disbursement ? row.loan_disbursement.loan_number : "")+" ")]}},{key:"app_amount",fn:function(ref){
                            var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-success"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode(row.app_amount, row.currency)))])]}},{key:"app_interest_rate",fn:function(ref){
                            var row = ref.row;
return [_vm._v(" "+_vm._s(row.app_interest_rate ? row.app_interest_rate + "%" : "")+" ")]}},{key:"co",fn:function(ref){
                            var row = ref.row;
return [_vm._v(" "+_vm._s(row.co ? _vm.locale == "kh" ? row.co.employee_name_kh : row.co.employee_name_en : "")+" ")]}},{key:"action",fn:function(ref){
                            var row = ref.row;
return [(
                        row.approve_status.approve_status_id == 2 &&
                            !row.loan_disbursement
                    )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('customerLoanRequest.loanDisbursement')),expression:"$t('customerLoanRequest.loanDisbursement')"}],staticClass:"text-indigo ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onMakeDisbur(row)}}},[_c('Icon',{attrs:{"type":"ios-navigate","size":"20"}})],1):_vm._e(),(row.approve_status.approve_status_id == 2)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('pdf')),expression:"$t('pdf')"}],staticClass:"text-blue ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.preview(row)}}},[_c('i',{staticClass:"far fa-file-pdf tw-text-sm tw-mr-1"})]):_vm._e(),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('customerLoanRequest.loanApproval')),expression:"$t('customerLoanRequest.loanApproval')"}],staticClass:"text-warning ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onApprove(row)}}},[_c('Icon',{attrs:{"type":"ios-checkmark-circle","size":"20"}})],1),(
                        !row._deleting &&
                            (row.approve_status.approve_status_id == 0 || row.approve_status.approve_status_id == 1)
                    )?_c('Poptip',{attrs:{"title":_vm.$t('are_you_sure_to_delete'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return (_vm.model.deleted_reason = ''); }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("journalEntry.reason")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.deleted_reason),expression:"model.deleted_reason",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid':
                                    _vm.errors.has('deleted_reason') ||
                                    _vm.$v.model.deleted_reason.$error
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.deleted_reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "deleted_reason", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.reasonErrors.length > 0)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.reasonErrors[0]))]):_vm._e(),(_vm.errors.has('deleted_reason'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("deleted_reason"))+" ")]):_vm._e(),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('span',[_vm._v("OK")])])])])]):(
                        !row._deleting &&
                            row.approve_status.approve_status_id == 0
                    )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1):_vm._e()]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('Drawer',{attrs:{"title":_vm.$t('filter'),"closable":false,"value":_vm.visible,"width":"300px"},on:{"on-close":function () { return (_vm.visible = false); }}},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t("branchName")))]),_c('ts-branch-filter',{on:{"filter":function (value) { return (_vm.branch_id = value); }}})],1)]),_c('contract-pdf-preview',{attrs:{"request-id":_vm.request_id},on:{"cancel":function (value) { return (_vm.contract_pdf_view = value); }},model:{value:(_vm.contract_pdf_view),callback:function ($$v) {_vm.contract_pdf_view=$$v},expression:"contract_pdf_view"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }